import { useNavigate } from 'react-router-dom';
import { ZOHO_FORMS } from '../../utils/Constant';
import MobileLogo from '../../img/logo1.png';
import Taekwondo_mobile_bg from '../../img/taekwondo_mobile_bg.jpg';;

const Pagewrapper = (props) =>  {

    const navigate = useNavigate();


    const redirectPage = (to_page) => {
        navigate('/'+ to_page);
    }

    return <div className='lg:mx-auto'>
            <div  className='h-51 lg:h-[20.5rem] lg:mx-auto overflow-hidden'>
                <div className='relative h-80 lg:h-[38rem]'>
                    <img src={Taekwondo_mobile_bg} className={'relative bottom-20 lg:bottom-[15rem] lg:h-[50rem] object-fill w-full h-full'} alt="Taekwondo_mobile_bg" />
                    <div className="h-[8rem] lg:h-[21rem] absolute w-full z-50 top-0 flex align-middle justify-center items-center">
                        <div className='text-center text-white'>
                            <div className="text-2xl  text-slate-100  lg:text-5xl lg:leading-[3.5rem] font-black">
                                {props.heading ? 
                                    <div >{props.heading}</div> :
                                    <div class="flex flex-col align-middle items-center lg:hidden hover:cursor-pointer">
                                        <img className='w-60 mb-10 mt-5' src={MobileLogo} alt= 'logo' />
                                    </div>
                                }
                            </div>
                            <div className=" font-extrabold mt-3 text-lg  text-slate-100  lg:text-2xl lg:leading-[3.5rem]">
                                {props.breadCrumbs && props.breadCrumbs.map((element, index) => 
                                    index === 0 ? 
                                            <span className='hover:underline cursor-pointer' onClick={() => redirectPage('')}>{element}</span>
                                        : 
                                            <>
                                                <span className='mx-4' style={{color: '#ff1515'}}>/</span>
                                                <span className='hover:underline cursor-pointer' style={{color: '#ff1515'}}>{element}</span>
                                            </>
                                )}
                                {
                                    props.showTrial ?
                                        <a className="bg-blue-800 text-sm border-2 border-blue-800 text-white px-10 py-2 mr-2 hover:cursor-pointer"
                                            onClick={() => window.open(ZOHO_FORMS, '_blank')} >
                                                 BOOK A TRIAL
                                        </a>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
}

export default Pagewrapper