import React, { useState, useEffect, useContext } from 'react';
import MobileLogo from '../img/logo1.png';
import Logo1 from '../img/logo1.png';
import { Link } from "react-scroll";
import { modelContext } from '../Provider';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { ZOHO_FORMS } from '../utils/Constant';

const Navbar = () => {

    const headerDefaultStyle = 'lg:h-[5.6rem] 2xl:h-fit';
    
    const [showDropdown, setShowDropdown] = useState(false)
    const [stickyClass, setStickyClass] = useState(`relative z-[60] ${headerDefaultStyle}`);
    const [hiddenBlock, setHiddenBlock] = useState('lg:hidden');
    const [model, setModel] = useContext(modelContext);
    const [hovered, setHovered] = useState(false);
    const [accHovered, setAccHovered] = useState(false);
    const [isHome, setIsHome] = useState(true);

    const navigate = useNavigate();
    
    const toggleHover = () => setHovered(!hovered);
    const toggleAccHover = () => setAccHovered(!accHovered);


    const location = useLocation()

    useEffect(() => {
      console.log('handle route change here', location.pathname.replace('/', ''))
      if(location.pathname.replace('/', '').length === 0)
        setIsHome(true)
      else 
        setIsHome(false)

    }, [location])

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
        window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    
    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            if(windowHeight > 150) {
                setHiddenBlock(`lg:block lg:h-[5.6rem] 2xl:[10rem]`)
                setStickyClass('fixed z-[60] top-0 left-0 z-50 bg-gray-100 lg:w-full transition ease-in-out duration-300 shadow-2xl')
            } else {
                setHiddenBlock('lg:hidden')
                setStickyClass(`relative z-[60] ${headerDefaultStyle}`);
            } 
        }
    };
    
    return (
        <div className='lg:h-38 lg:header lg:mx-auto'>
            
            <div class="h-28 px-5 flex flex-wrap items-center justify-between lg:h-14 lg:align-middle lg:items-center bg-gray-900 ">
                {/* Mobile View */}
                <div class="flex align-middle items-center lg:hidden hover:cursor-pointer" onClick={() => navigate('/')} >
                    <img className='w-60' src={MobileLogo} alt='logo' />
                </div>
                <button data-collapse-toggle="navbar-default" type="button" 
                        class="p-2 w-12 h-10 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-8 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div class={`${showDropdown ? 'block': 'hidden'} absolute left-0 top-20 w-full lg:hidden lg:w-auto z-[60]`} id="navbar-default">
                    <ul class="font-medium flex flex-col p-4 lg:p-0 mt-4 rounded-b-lg lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 lg:bg-white bg-gray-900 border-gray-700">
                        <li>
                            {isHome ?
                                    <Link to="top" spy={true} smooth={true}  duration={500} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">Home</Link>
                                    :
                                    <a href="/" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">Home</a>
                            }
                        </li>
                        <li>
                            <Link to="ourPrograms" spy={true} smooth={true}  duration={500} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">Our Programs</Link>
                                <ul class="pl-4 text-sm text-gray-400">
                                        <li>
                                            <a href="/little-tiger-program" class="block px-4 py-2 hover:bg-gray-100">Little Tiger</a>
                                        </li>
                                        <li>
                                            <a href="/youth-program" class="block px-4 py-2 hover:bg-gray-100">Youth Program</a>
                                        </li>
                                        <li>
                                            <a href="/teen-adult-program" class="block px-4 py-2 hover:bg-gray-100">Teen / Adult Program</a>
                                        </li>
                                        <li>
                                            <a href="/family-program" class="block px-4 py-2 hover:bg-gray-100">Family Program</a>
                                        </li>
                                </ul>
                        </li>
                        <li>
                            <Link to="events" spy={true} smooth={true}  duration={500} className={`block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent ${isHome ? 'block': 'hidden'}`}>Events</Link>
                        </li>
                        <li>
                            <Link to="announcements" spy={true} smooth={true}  duration={500} className={`block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent ${isHome ? 'block': 'hidden'}`}>Announcements</Link>
                        </li>
                        {/* <li>
                            <Link to="contactUs" spy={true} smooth={true}  duration={500} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">Contact us</Link>
                        </li> */}
                        <li>
                            <a href="/about" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">About</a>
                        </li>
                        <li>
                            <Link to="account" spy={true} smooth={true}  duration={500} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 text-white lg:hover:text-blue-500 hover:bg-gray-700 hover:text-white lg:hover:bg-transparent">Account</Link>
                                <ul class="pl-4 text-sm text-gray-400">
                                        <li>
                                        <a href="https://app.masterminstkd.com/signup" target='_blank' class="block px-4 py-2 hover:bg-gray-100">Register</a>
                                        </li>
                                        <li>
                                            <a href="https://app.masterminstkd.com/" target='_blank' class="block px-4 py-2 hover:bg-gray-100">Log in</a>
                                        </li>
                                </ul>
                        </li>
                    </ul>
                </div>
                {/* Desktop View */}
                <div class="hidden lg:flex">
                    <div style={{fontSize: '0.6rem'}} className='flex text-slate-300'>
                        <div className='mx-2'> 3695 Kirby Dr,Suite 107 Pearland TX 77584 </div>
                        {/* <div className='mx-2'> Mon - Fri : 2:00pm - 5:00pm </div> */}
                        <div className='mx-2'> (832)-493-8940 </div>
                    </div>
                </div>
                <div class="hidden lg:flex">
                    <div className='flex ml-auto'>
                        <a className='mx-2' href= 'https://www.facebook.com/profile.php?id=100093701297033&mibextid=ZbWKwL' target='_blank'>
                            <img alt="" fetchpriority="high" 
                            src="https://static.wixstatic.com/media/23fd2a2be53141ed810f4d3dcdcd01fa.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/23fd2a2be53141ed810f4d3dcdcd01fa.png" 
                            style={{width: "22px", height: "22px", objectFit: "cover"}}
                            />
                        </a>
                        <a className='mx-2' href= 'https://instagram.com/masterminswct?igshid=MzRlODBiNWFlZA==' target='_blank'>
                            <img alt="" fetchpriority="high" 
                            src="https://static.wixstatic.com/media/81af6121f84c41a5b4391d7d37fce12a.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/81af6121f84c41a5b4391d7d37fce12a.png" 
                            style={{width: "22px", height: "22px", objectFit: "cover"}}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className={`hidden ${hiddenBlock}`}>
                
            </div>
            <div class={`hidden lg:block lg:w-[95%] lg:mx-auto ${stickyClass}`}>
                <div class="grid grid-cols-3 gap-4">
                    <div class="flex align-middle justify-center items-center hover:cursor-pointer" onClick={() => navigate('/')}>
                        <img className='w-100 ' src={Logo1} />
                    </div>
                    <div class="text-l text-gray-600  col-span-2 flex flex justify-end items-center"> 
                        {
                            isHome ? 
                                <Link  to="top" spy={true} smooth={true}  duration={500} className='mx-4 tracking-wider highligth'> HOME </Link> 
                                :
                                <a href="/" className='mx-4 tracking-wider highligth'> HOME </a>
                        }
                        <div className='relative' onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                            <Link to="ourPrograms" spy={true} smooth={true}  duration={500}  
                                className='mx-4 tracking-wider highligth flex items-center gap-2'> <div>OUR PROGRAMS </div> <BsChevronDown /> </Link>
                            <div id="dropdownHover" className={`${hovered ? 'block' : 'hidden'} z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}>
                                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownHoverButton">
                                    <li>
                                        <a href="/little-tiger-program" class="block px-4 py-2 hover:bg-gray-100">Little Tiger</a>
                                    </li>
                                    <li>
                                        <a href="/youth-program" class="block px-4 py-2 hover:bg-gray-100">Youth Program</a>
                                    </li>
                                    <li>
                                        <a href="/teen-adult-program" class="block px-4 py-2 hover:bg-gray-100">Teen / Adult Program</a>
                                    </li>
                                    <li>
                                        <a href="/family-program" class="block px-4 py-2 hover:bg-gray-100">Family Program</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Link to="events" spy={true} smooth={true}  duration={500}   className={`mx-4 tracking-wider highligth ${isHome ? 'block': 'hidden'}`}> EVENTS </Link>
                        {/* <Link to="schedule" spy={true} smooth={true}  duration={500}   className='mx-4 tracking-wider highligth'> SCHEDULE </Link> */}
                        <Link to="announcements" spy={true} smooth={true}  duration={500}   className={`mx-4 tracking-wider highligth ${isHome ? 'block': 'hidden'}`}> ANNOUNCEMENTS </Link>
                        {/* <Link to="contactUs" spy={true} smooth={true}  duration={500}   className='mx-4 tracking-wider highligth'> CONTACT US </Link> */}
                        <a href="/about" spy={true} smooth={true}  duration={500}   className='mx-4 tracking-wider highligth'> ABOUT </a>
                        <div className='relative' onMouseEnter={toggleAccHover} onMouseLeave={toggleAccHover}>
                            <Link to="account" spy={true} smooth={true}  duration={500}  
                                className='mx-4 tracking-wider highligth flex items-center gap-2'> <div>ACCOUNT </div> <BsChevronDown /> </Link>
                            <div id="dropdownHover" className={`${accHovered ? 'block' : 'hidden'} z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}>
                                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownHoverButton">
                                    <li>
                                        <a href="https://app.masterminstkd.com/signup" target='_blank' class="block px-4 py-2 hover:bg-gray-100">Register</a>
                                    </li>
                                    <li>
                                        <a href="https://app.masterminstkd.com/" target='_blank' class="block px-4 py-2 hover:bg-gray-100">Log in</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        { !isHome && <a onClick={() => setModel({show: !model.show, type: 'iframe'})}  target='_blank' spy={true} smooth={true}  duration={500}   className='mx-4 tracking-wider highligth bg-blue-800 border-2 border-blue-800 text-white px-10 py-2 mr-2 hover:cursor-pointer hover:text-gray-400' onClick={() => window.open(ZOHO_FORMS, '_blank')} > BOOK A TRIAL </a> }
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default Navbar;